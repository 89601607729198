import { DROPDOWN_VARIANT } from "components/editorComponents/WidgetQueryGeneratorForm/CommonControls/DatasourceDropdown/types";
import { ValidationTypes } from "constants/WidgetValidation";
import type { ActionData } from "ee/reducers/entityReducers/actionsReducer";
import type { ModuleInstanceData } from "ee/constants/ModuleInstanceConstants";
import { PluginName } from "entities/Plugin";

export const propertyPaneContent = [
  {
    sectionName: "Agent",
    children: [
      {
        helpText: "Gives the open AI Agent a name to be displayed in chat",
        propertyName: "assistantName",
        label: "Agent Name",
        controlType: "INPUT_TEXT",
        isJSConvertible: false,
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        defaultValue: "",
      },
      {
        helpText: "Configures an initial agent message",
        propertyName: "initialAssistantMessage",
        label: "Welcome Message",
        controlType: "INPUT_TEXT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        defaultValue: "",
      },
      {
        helpText: "Adds a placeholder text to the message input box",
        propertyName: "promptInputPlaceholder",
        label: "Placeholder",
        controlType: "INPUT_TEXT",
        isJSConvertible: false,
        isBindProperty: false,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
        defaultValue: "",
      },
      {
        helpText: "Configures initial agent suggestions",
        propertyName: "initialAssistantSuggestions",
        label: "Suggested Prompts",
        controlType: "OPTION_INPUT",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.ARRAY },
        defaultValue: [],
        controlConfig: {
          allowEmpty: true,
        },
      },
    ],
  },
  {
    sectionName: "Data",
    children: [
      {
        propertyName: "chatQuery",
        helpText: "The query to be used for the chat",
        label: "Chat Query",
        controlType: "ONE_CLICK_BINDING_CONTROL",
        controlConfig: {
          showEditFieldsModal: false, // Shows edit field modals button in the datasource table control
          datasourceDropdownVariant: DROPDOWN_VARIANT.CONNECT_TO_QUERY, // Decides the variant of the datasource dropdown which alters the text and some options
          excludePrimaryColumnFromQueryGeneration: true, // Excludes the primary column from the query generation by default
          isConnectableToWidget: false, // Whether this widget can be connected to another widget like Table,List etc
          allowedDatasourceTypes: [PluginName.APPSMITH_AI],
          getQueryBindingValue: (query: ActionData | ModuleInstanceData) => {
            return query.config.name;
          },
        },
        isJSConvertible: false,
        isBindProperty: false,
        isTriggerProperty: false,
        dependencies: ["queryData", "queryRun"],
        updateHook: (
          _props: unknown,
          propertyPath: string,
          propertyValue: string,
        ) => {
          const propertiesToUpdate = [{ propertyPath, propertyValue }];

          propertiesToUpdate.push({
            propertyPath: "queryData",
            propertyValue: `{{${propertyValue}.data}}`,
          });
          propertiesToUpdate.push({
            propertyPath: "queryRun",
            propertyValue: propertyValue,
          });

          return propertiesToUpdate;
        },
      },
    ],
  },
  {
    sectionName: "Hidden props",
    children: [
      {
        propertyName: "queryData",
        label: "",
        controlType: "INPUT_TEXT",
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        hidden: () => true,
      },
      {
        propertyName: "queryRun",
        label: "",
        controlType: "INPUT_TEXT",
        isJSConvertible: false,
        isBindProperty: true,
        isTriggerProperty: false,
        hidden: () => true,
      },
    ],
  },
];
