export type ToolCall = {
  id: string;
  entity: {
    id: string;
    type: "Query" | "JSFunction";
    arguments: string;
    name: string;
    iconLocation: string;
  };
  isApprovalRequired: boolean;
} & (
  | {
      executionStatus: "pending" | "pendingApproval" | "inProgress";
    }
  | {
      executionStatus: "success";
      output: string;
    }
  | {
      executionStatus: "error";
      error: string;
    }
  | {
      executionStatus: "rejected";
      error: string;
    }
);

export function assertIsCompletedToolCall(
  toolCall: ToolCall,
): asserts toolCall is ToolCall & {
  executionStatus: "success" | "error" | "rejected";
} {
  if (
    toolCall.executionStatus === "pending" ||
    toolCall.executionStatus === "pendingApproval" ||
    toolCall.executionStatus === "inProgress"
  ) {
    throw new Error("Tool call is in pending state");
  }
}
