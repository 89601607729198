// TODO add the correct type when BE finish a polishing

import type { MessageCitation } from "./messageCitation";
import type { PromptSuggestion } from "./promptSuggestion";
import type { ToolCall } from "./toolCall";

interface BaseMessage {
  id: string;
  createdAt: number | Date;
}

export interface UserMessage extends BaseMessage {
  role: "user"; // discriminator
  content: string;
  type: "text";
}

export type BaseAssistantMessage = BaseMessage & {
  role: "assistant"; // discriminator
};

export type AssistantTextMessage = BaseAssistantMessage & {
  type: "text" | "error";
  content: string;
  promptSuggestions?: PromptSuggestion[];
  citations: MessageCitation[];
};

export type AssistantActionRequestMessage = BaseAssistantMessage & {
  type: "action_request";
  runId: string;
  threadId: string;
  prevMessageId: string;
  outputSubmissionStatus: "pending" | "inProgress" | "success" | "error";
  content: ToolCall[];
};

export type AssistantMessage =
  | AssistantTextMessage
  | AssistantActionRequestMessage;

export type Message = UserMessage | AssistantMessage;

export const isUserMessage = (message: Message): message is UserMessage => {
  return message.role === "user";
};

export const isAssistantMessage = (
  message: Message,
): message is AssistantMessage => {
  return message.role === "assistant";
};

export const isAssistantTextMessage = (
  message: Message,
): message is AssistantTextMessage => {
  return isAssistantMessage(message) && message.type === "text";
};

export const isAssistantErrorMessage = (
  message: Message,
): message is AssistantTextMessage => {
  return isAssistantMessage(message) && message.type === "error";
};

export const isAssistantActionRequestMessage = (
  message: Message,
): message is AssistantActionRequestMessage => {
  return isAssistantMessage(message) && message.type === "action_request";
};

export function assertAssistantActionRequestMessage(
  message: Message,
): asserts message is AssistantActionRequestMessage {
  if (!isAssistantActionRequestMessage(message)) {
    throw new Error("Message is not an assistant action request message");
  }
}

export const isReadyForSubmission = (
  message: AssistantActionRequestMessage,
): boolean => {
  return (
    message.outputSubmissionStatus === "pending" &&
    message.content.every(
      (toolCall) =>
        toolCall.executionStatus === "success" ||
        toolCall.executionStatus === "error" ||
        toolCall.executionStatus === "rejected",
    )
  );
};
