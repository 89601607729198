import React from "react";
import { Button, Text } from "@appsmith/wds";
import styles from "./styles.module.css";

interface ChatSidebarErrorProps {
  errorMessage: string | null;
  onRetry?: () => void;
}

export const ChatSidebarError = (props: ChatSidebarErrorProps) => {
  const { errorMessage, onRetry } = props;

  if (!errorMessage) return null;

  return (
    <div className={styles.sidebarError}>
      <Text color="negative" textAlign="center" wordBreak="break-word">
        {errorMessage}
      </Text>
      {onRetry && (
        <Button color="neutral" onPress={onRetry} size="small" variant="subtle">
          Retry
        </Button>
      )}
    </div>
  );
};
