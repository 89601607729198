import { Text } from "@appsmith/wds";
import type { VisualizationElements } from "entities/Action";
import { Result } from "PluginActionEditor/components/PluginActionResponse/components/Visualization/components/Result";
import React, { useEffect, useState, useRef } from "react";
import { type ToolCall } from "../types/toolCall";
import styles from "./styles.module.css";
import ReadOnlyEditor from "components/editorComponents/ReadOnlyEditor";
interface ToolCallResponseViewerProps {
  toolCall: ToolCall;
  visualizationEnabled?: boolean;
  visualizationElements: VisualizationElements | null;
}

export const ToolCallResponseViewer = ({
  toolCall,
  visualizationElements,
  visualizationEnabled = false,
}: ToolCallResponseViewerProps) => {
  const CodeViewRef = useRef<HTMLDivElement>(null);

  const [codeViewHeight, setCodeViewHeight] = useState<number>(0);

  useEffect(function handleDynamicHeight() {
    if (CodeViewRef.current) {
      const height = CodeViewRef.current.clientHeight;

      setCodeViewHeight(height);
    }
  }, []);

  if (
    toolCall.executionStatus !== "success" &&
    toolCall.executionStatus !== "error"
  ) {
    return null;
  }

  if (toolCall.executionStatus === "error") {
    return (
      <div className={styles.toolCallResponseViewer}>
        <Text className={styles.error}>{toolCall.error}</Text>
      </div>
    );
  }

  if (visualizationEnabled && visualizationElements) {
    return (
      <div className={styles.toolCallResponseViewer}>
        <Result data={toolCall.output} elements={visualizationElements} />
      </div>
    );
  }

  return (
    <div className={styles.toolCallResponseViewer} ref={CodeViewRef}>
      <ReadOnlyEditor
        folding
        height={`${codeViewHeight}px`}
        input={{ value: toolCall.output }}
      />
    </div>
  );
};
