import { createParser, type EventSourceMessage } from "eventsource-parser";

export class StreamReader {
  private decoder: TextDecoder;
  private reader: ReadableStreamDefaultReader<Uint8Array>;
  private parser: ReturnType<typeof createParser>;
  private eventQueue: EventSourceMessage[] = [];

  constructor(reader: ReadableStreamDefaultReader<Uint8Array>) {
    this.decoder = new TextDecoder("utf-8");
    this.reader = reader;
    this.parser = createParser({
      onEvent: (event) => {
        this.eventQueue.push(event);
      },
    });
  }

  async *read() {
    while (true) {
      const { done, value } = await this.reader.read();

      if (done) {
        // Yield any remaining events before breaking
        while (this.eventQueue.length > 0) {
          yield this.eventQueue.shift()!;
        }

        break;
      }

      const chunk = this.decoder.decode(value);

      this.parser.feed(chunk);

      // Yield all events that have been collected
      while (this.eventQueue.length > 0) {
        yield this.eventQueue.shift()!;
      }
    }
  }
}
