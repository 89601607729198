import React from "react";
import {
  Text,
  Flex,
  Button,
  IconButton,
  type SidebarState,
} from "@appsmith/wds";

import styles from "./styles.module.css";

interface ChatHeaderProps {
  title?: string;
  onDeleteThread?: () => void;
  isDeleteThreadDisabled?: boolean;
  sidebarState?: SidebarState;
  setSidebarState?: (state: SidebarState) => void;
}

export const ChatHeader = (props: ChatHeaderProps) => {
  const {
    isDeleteThreadDisabled,
    onDeleteThread,
    setSidebarState,
    sidebarState,
    title,
  } = props;

  return (
    <Flex alignItems="center" className={styles.header} gap="spacing-3">
      <Text fontWeight={600} lineClamp={1} size="subtitle">
        {title}
      </Text>
      {onDeleteThread && (
        <IconButton
          className={styles.deleteButton}
          color="neutral"
          data-testid="t--ai-chat-header-delete-button"
          icon="trash"
          isDisabled={isDeleteThreadDisabled}
          onPress={onDeleteThread}
          variant="ghost"
        />
      )}
      <Button
        color="neutral"
        icon={
          sidebarState === "collapsed"
            ? "layout-sidebar-right-expand"
            : "layout-sidebar-right-collapse"
        }
        onPress={() => {
          if (setSidebarState) {
            setSidebarState(
              sidebarState === "collapsed" ? "expanded" : "collapsed",
            );
          }
        }}
        variant="ghost"
      />
    </Flex>
  );
};
