import type { ApiResponseError } from "api/types";

/**
 * in case of embeds, we want to set the height to the full canvas height
 * otherwise, we want to subtract the outer spacing from the canvas height
 * when the ai chat widget is marked for fit page
 */
export const getFitPageChatHeight = (
  canvasHeight: number,
  isEmbed: boolean,
) => {
  if (isEmbed) return `calc(${canvasHeight}px`;

  return `calc(${canvasHeight}px - (var(--outer-spacing-4) * 2)`;
};

export function assertIsBlob(document: unknown): asserts document is Blob {
  if (typeof document !== "object" || !(document instanceof Blob)) {
    throw new Error("Document is not a Blob");
  }
}

export function assertIsString(document: unknown): asserts document is string {
  if (typeof document !== "string") {
    throw new Error("Document is not a string");
  }
}

export function isApiResponseError(error: unknown): error is ApiResponseError {
  return typeof error === "object" && error !== null && "message" in error;
}
