import React, { useMemo } from "react";
import { Spinner, Text } from "@appsmith/wds";
import styles from "./styles.module.css";
import type { CitationDetails } from "./types";
import { PdfDocumentViewer } from "./citationViewers/PdfDocumentViewer";
import { TextDocumentViewer } from "./citationViewers/TextDocumentViewer";
import { MarkdownDocumentViewer } from "./citationViewers/MarkdownDocumentViewer";
import { assertIsBlob, assertIsString } from "../helpers";
import { ChatSidebarError } from "./ChatSidebarError";

interface ChatDocumentViewerProps {
  isAnimating: boolean;
  citation: CitationDetails | null;
  document?: Blob | string | null;
  isLoading: boolean;
  citationErrorMessage: string | null;
  hasDocumentError: boolean;
  selectedCitationId?: string | null;
  retryCitation: () => void;
  retryDocument: () => void;
}

export const ChatDocumentViewer = (props: ChatDocumentViewerProps) => {
  const {
    citation,
    citationErrorMessage,
    document,
    hasDocumentError,
    isAnimating,
    isLoading,
    retryCitation,
    retryDocument,
  } = props;

  const content = useMemo(() => {
    if (!citation || !document || isAnimating) return null;

    switch (citation.integrationType) {
      case "PDF":
        assertIsBlob(document);

        return (
          <PdfDocumentViewer
            citation={citation}
            document={document}
            hasError={hasDocumentError}
            isResizing={isAnimating}
          />
        );
      case "TEXT":
        assertIsString(document);

        return (
          <TextDocumentViewer
            citation={citation}
            document={document}
            hasError={hasDocumentError}
          />
        );
      case "MD":
        assertIsString(document);

        return (
          <MarkdownDocumentViewer
            citation={citation}
            document={document}
            hasError={hasDocumentError}
          />
        );
      default:
        return null;
    }
  }, [document, citation, isAnimating, hasDocumentError]);

  return (
    <div className={styles.documentViewer}>
      {isLoading && (
        <div className={styles.documentViewerSpinner}>
          <Spinner />
          <Text>Loading the document...</Text>
        </div>
      )}
      {(citationErrorMessage || hasDocumentError) && !isLoading && (
        <ChatSidebarError
          errorMessage={citationErrorMessage}
          onRetry={citationErrorMessage ? retryCitation : retryDocument}
        />
      )}
      {!isLoading && !citationErrorMessage && !hasDocumentError && content}
    </div>
  );
};
