import {
  Button,
  Flex,
  Sidebar,
  SidebarProvider,
  type SidebarState,
} from "@appsmith/wds";
import { getActions, getJSCollections } from "ee/selectors/entitiesSelector";
import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getIsViewMode } from "selectors/editorSelectors";
import { useAIChatHeight } from "../hooks/useAIChatHeight";
import { ChatCitationEmpty } from "./ChatCitationEmpty";
import { ChatDocumentViewer } from "./ChatDocumentViewer";
import { ChatHeader } from "./ChatHeader";
import { ChatInputSection } from "./ChatInputSection";
import { ChatMessageList } from "./ChatMessageList";
import { ChatSkeleton } from "./ChatSkeleton";
import { ChatWebPageViewer } from "./citationViewers/ChatWebPageViewer";
import { useFetchDocument } from "./citationViewers/useFetchDocument";
import styles from "./styles.module.css";
import { ToolCallResponseViewer } from "./ToolCallResponseViewer";
import { isAssistantTextMessage, type AIChatProps } from "./types";
import type { ToolCall } from "./types/toolCall";
import { useFetchCitation } from "./useFetchCitation";
import { cancelRun } from "../api";
import { toast } from "@appsmith/ads";

export const AIChat = (props: AIChatProps) => {
  const {
    chatTitle,
    isQueryLoading,
    isThreadLoading,
    isWaitingForResponse = false,
    onApplyAssistantSuggestion,
    onAttachFiles,
    onDeleteThread: onDeleteThreadProp,
    onPromptChange,
    onSubmit,
    onToolCallApprove,
    onToolCallReject,
    prompt,
    promptInputPlaceholder,
    queryId,
    runId = "",
    size,
    thread,
    threadId,
  } = props;

  const { search } = window.location;
  const queryParams = new URLSearchParams(search);
  const isEmbed = queryParams.get("embed") === "true";
  const chatHeight = useAIChatHeight(size, isEmbed);
  const isDeleteThreadDisabled =
    thread.length === 1 || isThreadLoading || isWaitingForResponse;
  const [sidebarState, setSidebarState] = useState<SidebarState>("collapsed");
  const [
    isFCResponseVisualizationEnabled,
    setIsFCResponseVisualizationEnabled,
  ] = useState(false);
  const [selectedCitationId, setSelectedCitationId] = useState<string | null>(
    null,
  );
  const { citation: selectedMessageCitation, message: selectedMessage } =
    useMemo(() => {
      const message = thread
        .filter(isAssistantTextMessage)
        .find((t) => t.citations.find((c) => c.id === selectedCitationId));
      const citation = message?.citations.find(
        (c) => c.id === selectedCitationId,
      );

      return { message, citation };
    }, [selectedCitationId, thread]);
  const [selectedToolCall, setSelectedToolCall] = useState<ToolCall | null>(
    null,
  );
  const viewMode = useSelector(getIsViewMode);

  const {
    citation: selectedCitation,
    errorMessage: citationErrorMessage,
    isLoading: isCitationLoading,
    retry: retryFetchCitation,
  } = useFetchCitation({
    queryId,
    citationId: selectedCitationId,
    messageId: selectedMessage?.id || null,
    messageCitation: selectedMessageCitation,
    threadId,
    viewMode,
  });
  const actions = useSelector(getActions);
  const jsCollections = useSelector(getJSCollections);
  const {
    document,
    hasError: isDocumentError,
    isLoading: isDocumentLoading,
    retry: retryFetchDocument,
  } = useFetchDocument(
    selectedCitation?.url,
    selectedCitation?.integrationType,
    selectedCitationId,
  );

  const visualizationElements = useMemo(() => {
    if (!selectedToolCall) return null;

    if (selectedToolCall.entity.type === "Query") {
      const action = actions.find(
        (a) => a.config.id === selectedToolCall.entity.id,
      );

      if (!action) return null;

      return action.config.visualization?.result ?? null;
    }

    if (selectedToolCall.entity.type === "JSFunction") {
      const jsCollection = jsCollections.find((a) =>
        a.config.actions.find((a) => a.id === selectedToolCall.entity.id),
      );

      if (!jsCollection) return null;

      const jsFunction = jsCollection.config.actions.find(
        (a) => a.id === selectedToolCall.entity.id,
      );

      if (!jsFunction) return null;

      return jsFunction.visualization?.result ?? null;
    }

    return null;
  }, [selectedToolCall, actions]);

  const onCitationOpen = useCallback(
    (citationId: string) => {
      setSelectedCitationId(citationId);
      setSidebarState("expanded");
    },
    [setSelectedCitationId],
  );

  // along with deleting the thread, we also reset the selected citation when thread is deleted,
  // this is to ensure that the sidebar is resetted when the thread is deleted
  const onDeleteThread = useCallback(() => {
    onDeleteThreadProp?.();
    setSelectedCitationId(null);
  }, [onDeleteThreadProp]);

  const onStop = useCallback(async () => {
    if (runId) {
      try {
        await cancelRun(queryId, threadId, runId, viewMode);
      } catch (error) {
        toast.show("Failed to cancel run", {
          kind: "error",
        });
      }
    }
  }, [queryId, threadId, runId, viewMode]);

  return (
    <div
      className={styles.aiChat}
      style={
        {
          "--chat-height": chatHeight,
        } as React.CSSProperties
      }
    >
      {isQueryLoading && <ChatSkeleton />}
      {!isQueryLoading && (
        <>
          <ChatHeader
            isDeleteThreadDisabled={isDeleteThreadDisabled}
            onDeleteThread={onDeleteThread}
            setSidebarState={setSidebarState}
            sidebarState={sidebarState}
            title={chatTitle}
          />
          <SidebarProvider
            className={styles.sidebarWrapper}
            onStateChange={setSidebarState}
            side="end"
            state={sidebarState}
          >
            <Flex direction="column" minWidth="sizing-0" width="100%">
              <ChatMessageList
                isThreadLoading={isThreadLoading}
                isWaitingForResponse={isWaitingForResponse}
                onApplyAssistantSuggestion={onApplyAssistantSuggestion}
                onCitationOpen={onCitationOpen}
                onToolCallApprove={onToolCallApprove}
                onToolCallReject={onToolCallReject}
                onToolCallSelect={setSelectedToolCall}
                selectedCitationId={selectedCitationId}
                thread={thread}
              />
              <ChatInputSection
                isRunning={!!runId}
                isThreadLoading={isThreadLoading}
                isWaitingForResponse={isWaitingForResponse}
                onAttachFiles={onAttachFiles}
                onPromptChange={onPromptChange}
                onStop={onStop}
                onSubmit={onSubmit}
                prompt={prompt}
                promptInputPlaceholder={promptInputPlaceholder}
              />
            </Flex>
            <Sidebar
              extraTitleButton={
                selectedToolCall ? (
                  <Button
                    color="neutral"
                    icon="code"
                    onPress={() => {
                      setIsFCResponseVisualizationEnabled((v) => !v);
                    }}
                    variant={
                      isFCResponseVisualizationEnabled ? "filled" : "ghost"
                    }
                  />
                ) : undefined
              }
              title={
                selectedCitation?.name || selectedToolCall?.entity.name || ""
              }
            >
              {({ isAnimating }) => {
                if (!selectedCitationId && !selectedToolCall) {
                  return <ChatCitationEmpty />;
                }

                if (
                  selectedCitationId &&
                  selectedMessageCitation?.integrationType === "WEB_SCRAPE"
                ) {
                  return <ChatWebPageViewer url={selectedCitation?.url} />;
                }

                if (selectedCitationId) {
                  return (
                    <ChatDocumentViewer
                      citation={selectedCitation}
                      citationErrorMessage={citationErrorMessage}
                      document={document}
                      hasDocumentError={isDocumentError}
                      isAnimating={isAnimating}
                      isLoading={isCitationLoading || isDocumentLoading}
                      retryCitation={retryFetchCitation}
                      retryDocument={retryFetchDocument}
                      selectedCitationId={selectedCitationId}
                    />
                  );
                }

                if (selectedToolCall) {
                  return (
                    <ToolCallResponseViewer
                      toolCall={selectedToolCall}
                      visualizationElements={visualizationElements}
                      visualizationEnabled={isFCResponseVisualizationEnabled}
                    />
                  );
                }

                return null;
              }}
            </Sidebar>
          </SidebarProvider>
        </>
      )}
    </div>
  );
};
