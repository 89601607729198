import type { ICONS } from "@appsmith/wds";
import { Button, Flex, Icon, Text, CodeBlock, useSidebar } from "@appsmith/wds";
import clsx from "clsx";
import React, { memo, useState, type ReactNode } from "react";
import type { ToolCall } from "../types/toolCall";
import styles from "./styles.module.css";

interface ChatMessageToolCallItemProps {
  messageId: string;
  toolCall: ToolCall;
  onApprove: (messageId: string, toolCallId: string) => void;
  onReject: (messageId: string, toolCallId: string) => void;
  onSelect: (toolCall: ToolCall) => void;
}

export const ChatMessageToolCallItem = memo(
  ({
    messageId,
    onApprove,
    onReject,
    onSelect,
    toolCall,
  }: ChatMessageToolCallItemProps) => {
    const [isArgumentsOpen, setIsArgumentsOpen] = useState(false);
    const { setState } = useSidebar();
    const { entity, executionStatus, id } = toolCall;

    const resolveStatusDescription = (): string | ReactNode | null => {
      switch (executionStatus) {
        case "pendingApproval":
          return `Function ${entity.name} requires approval`;
        case "inProgress":
          return `Executing function ${entity.name}`;
        case "success":
          return (
            <Flex alignItems="center" direction="row" gap="spacing-2">
              Executed function
              <div
                className={styles.functionNameClickable}
                onClick={() => {
                  onSelect(toolCall);
                  setState("expanded");
                }}
              >
                {entity.name}
              </div>
            </Flex>
          );
        case "error":
          return `Something went wrong executing function ${entity.name}`;
        case "rejected":
          return `Rejected execution of ${entity.name}`;
        default:
          return entity.name;
      }
    };

    const resolveActionButtons = (): ReactNode | null => {
      switch (executionStatus) {
        case "pendingApproval":
          return (
            <Flex gap="spacing-2">
              <Button
                color="neutral"
                onPress={() => onApprove(messageId, id)}
                size="small"
                variant="outlined"
              >
                Approve
              </Button>
              <Button
                color="neutral"
                onPress={() => onReject(messageId, id)}
                size="small"
                variant="outlined"
              >
                Deny
              </Button>
            </Flex>
          );
        default:
          return null;
      }
    };

    const resolveIconName = (): keyof typeof ICONS | null => {
      switch (executionStatus) {
        case "pendingApproval":
          return "exclamation-circle";
        case "inProgress":
          return "sparkles";
        case "success":
          return "circle-check";
        case "error":
        case "rejected":
          return "cancel";
        default:
          return null;
      }
    };

    const shouldShowArguments =
      entity.arguments &&
      entity.arguments.length > 0 &&
      executionStatus === "pendingApproval";
    const icon = resolveIconName();

    return (
      <div className={styles.chatMessageToolCallItem}>
        <Flex alignItems="center" direction="row" gap="spacing-3">
          {icon && (
            <Icon
              className={clsx(styles.icon, {
                [styles.iconInProgress]: executionStatus === "inProgress",
                [styles.iconPendingApproval]:
                  executionStatus === "pendingApproval",
                [styles.iconSuccess]: executionStatus === "success",
                [styles.iconError]:
                  executionStatus === "error" || executionStatus === "rejected",
              })}
              name={icon}
            />
          )}
          <Text
            className={clsx({
              [styles.inProgressText]: executionStatus === "inProgress",
            })}
            color="neutral-subtle"
          >
            {resolveStatusDescription()}
          </Text>
        </Flex>
        <Flex
          direction="column"
          gap="spacing-3"
          isInner
          paddingLeft="spacing-6"
          width="100%"
        >
          {shouldShowArguments && (
            <div className={styles.arguments}>
              <Flex
                alignItems="center"
                className={styles.argumentsHeader}
                direction="row"
                gap="spacing-1"
                isInner
                onClick={() => setIsArgumentsOpen((prev) => !prev)}
                width="100%"
              >
                <Icon
                  color="neutral"
                  name={isArgumentsOpen ? "chevron-down" : "chevron-right"}
                  size="small"
                />
                <Text color="neutral" size="caption">
                  Parameters
                </Text>
              </Flex>

              {isArgumentsOpen && (
                <CodeBlock
                  PreTag="div"
                  customStyle={{
                    backgroundColor: "var(--color-bg-neutral-subtle)",
                  }}
                  language="json"
                  showLineNumbers
                  useInlineStyles
                >
                  {JSON.stringify(JSON.parse(entity.arguments), null, 2)}
                </CodeBlock>
              )}
            </div>
          )}

          {resolveActionButtons()}
        </Flex>
      </div>
    );
  },
);
