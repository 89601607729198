import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import styles from "./styles.module.css";
import type { PdfDocumentViewerProps } from "./types";
import {
  getPageScale,
  normalizePageScale,
  resolveCitationHighlightStyle,
  scrollToCitation,
  shouldShowCitationHighlight,
} from "./helpers";
import type { OnDocumentLoadSuccess } from "react-pdf/dist/cjs/shared/types";
import { VariableSizeList as List } from "react-window";
import { ZoomControls } from "./components/ZoomControls";
import { ChatSidebarError } from "../../ChatSidebarError";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

type PdfDocumentProxy = Parameters<OnDocumentLoadSuccess>[0];
type PageViewport = ReturnType<
  Awaited<ReturnType<PdfDocumentProxy["getPage"]>>["getViewport"]
>;

const options = {
  cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
};

const ZOOM_SCALE_STEP = 0.25;

const _PdfDocumentViewer = (props: PdfDocumentViewerProps) => {
  const {
    citation,
    document,
    hasError: hasErrorProp,
    isResizing,
    ...rest
  } = props;
  const documentNodeRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [pdf, setPdf] = useState<PdfDocumentProxy | null>(null);
  const [pageScale, setPageScale] = useState<number | undefined>(1); // Set default scale to 1
  const highlightArea = citation.highlightArea;
  const [pageViewports, setPageViewports] = useState<PageViewport[] | null>(
    null,
  );
  const [hasError, setHasError] = useState(false);
  const listRef = useRef<List>(null);
  // Why we need scrollId? The reason is, we need a state to trigger the scroll on some state change.
  // Now that state cannot be citation change ( because first need to calculate the page viewport and pageScale and then scroll to the citation)
  // or it cannot be pageViewport or pageScale as they are certain events where want to scrolling to happen but those states don't change. So we created scrollId state to trigger the scroll.
  const [scrollId, setScrollId] = useState<string | null>(null);
  // We need a variable to store if we have scrolled to the citation so that we don't scroll again if page is re-rendered
  // again when moves to other pages  witth manual scroll and comes back to it again.
  // Note:  This can be state as it would re-render the pdf
  const isScrolledRef = useRef(false);

  function onDocumentLoadSuccess(nextPdf: PdfDocumentProxy) {
    setPdf(nextPdf);
  }

  function onDocumentLoadError() {
    setHasError(true);
  }

  useEffect(
    function updateContainerWidthAfterResizing() {
      if (!documentNodeRef.current || isResizing) return;

      const containerWidth = documentNodeRef.current.clientWidth || 0;
      const containerHeight = documentNodeRef.current.clientHeight || 0;

      setContainerWidth(containerWidth);
      setContainerHeight(containerHeight);

      // Only calculate page scale if highlight area exists
      if (highlightArea) {
        setPageScale(
          getPageScale(containerWidth, highlightArea.start, highlightArea.end),
        );
      }

      setScrollId(crypto.randomUUID());
      isScrolledRef.current = false;
    },
    [isResizing],
  );

  useEffect(
    // here we calculate the page viewport and page scale. Page viewports are used to calculate the height of the page in the virtual list.
    function handlePdfChange() {
      if (!pdf) return;

      (async () => {
        const pageNumbers = Array.from(new Array(pdf.numPages)).map(
          (_, index) => index + 1,
        );

        const nextPageViewports = await Promise.all(
          pageNumbers.map(async (pageNumber) =>
            pdf
              .getPage(pageNumber)
              .then((page) => page.getViewport({ scale: 1 })),
          ),
        );

        setPageViewports(nextPageViewports);

        // Only calculate page scale if highlight area exists
        if (highlightArea) {
          setPageScale(
            getPageScale(
              containerWidth,
              highlightArea.start,
              highlightArea.end,
            ),
          );
        }

        setScrollId(crypto.randomUUID());
        isScrolledRef.current = false;
      })();
    },
    [pdf, containerWidth],
  );

  useEffect(
    function handleDocumentChange() {
      setPageViewports(null);
    },
    [document],
  );

  useEffect(
    function handleScrollIdChange() {
      if (!scrollId || !pdf || !pageViewports) return;

      listRef.current?.resetAfterIndex(0);

      // Only scroll to specific page if highlight area exists
      if (highlightArea) {
        listRef.current?.scrollToItem(highlightArea.start.pageNumber - 1);
      }
    },
    [scrollId, pdf, pageViewports, containerWidth, highlightArea],
  );

  function getPageHeight(pageIndex: number) {
    if (!pageViewports || !pageScale) {
      throw new Error("getPageHeight() called too early");
    }

    const pageViewport = pageViewports[pageIndex];
    const heightWidthRatio = pageViewport.height / pageViewport.width;
    const actualHeight = heightWidthRatio * containerWidth * pageScale;

    return actualHeight;
  }

  function onPageRenderSuccess(pageNumber: number) {
    if (
      highlightArea &&
      pageNumber === highlightArea.start.pageNumber &&
      !isScrolledRef.current
    ) {
      scrollToCitation(
        highlightArea.start.pageNumber,
        styles.citationHighlight,
        listRef,
        documentNodeRef,
      );
      isScrolledRef.current = true;
    }
  }

  const PDFPage = (rowProps: { index: number; style: React.CSSProperties }) => {
    const { index, style } = rowProps;

    return (
      <div key={`page_${index + 1}-${pageScale}`} style={style}>
        <Page
          key={`page_${index + 1}`}
          loading=""
          onLoadSuccess={() => onPageRenderSuccess(index + 1)}
          pageNumber={index + 1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          scale={pageScale}
          width={containerWidth}
        >
          {highlightArea &&
            shouldShowCitationHighlight(
              index + 1,
              highlightArea.start,
              highlightArea.end,
            ) && (
              <div
                className={styles.citationHighlight}
                style={resolveCitationHighlightStyle({
                  end: highlightArea.end,
                  start: highlightArea.start,
                  pageNumber: index + 1,
                })}
              />
            )}
        </Page>
      </div>
    );
  };

  const handleZoomInButtonClick = useCallback(() => {
    setPageScale((prevPageScale = 1) =>
      normalizePageScale(prevPageScale + ZOOM_SCALE_STEP),
    );
    setScrollId(crypto.randomUUID());
    isScrolledRef.current = false;
  }, []);

  const handleZoomOutButtonClick = useCallback(() => {
    setPageScale((prevPageScale = 1) =>
      normalizePageScale(prevPageScale - ZOOM_SCALE_STEP),
    );
    setScrollId(crypto.randomUUID());
    isScrolledRef.current = false;
  }, []);

  return (
    <div className={styles.pdfDocumentViewer} {...rest}>
      <div className={styles.content}>
        {(hasError || hasErrorProp) && (
          <ChatSidebarError errorMessage="Can't load the file..." />
        )}

        <div
          className={styles.document}
          data-hidden={Boolean(!pageScale || isResizing)}
          ref={documentNodeRef}
        >
          <Document
            error=""
            file={document}
            loading=""
            onLoadError={onDocumentLoadError}
            onLoadSuccess={onDocumentLoadSuccess}
            options={options}
          >
            {pdf && pageViewports && document && pageScale && !isResizing && (
              <List
                height={containerHeight}
                itemCount={pdf.numPages}
                itemSize={getPageHeight}
                ref={listRef}
                width={containerWidth}
              >
                {PDFPage}
              </List>
            )}
          </Document>
        </div>
      </div>

      <ZoomControls
        onZoomIn={handleZoomInButtonClick}
        onZoomOut={handleZoomOutButtonClick}
      />
    </div>
  );
};

export const PdfDocumentViewer = memo(_PdfDocumentViewer);
