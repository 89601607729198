import { Markdown, Spinner, Text } from "@appsmith/wds";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import type { TextDocumentViewerProps } from "./types";
import chatStyles from "../../styles.module.css";
import { ChatSidebarError } from "../../ChatSidebarError";

export const TextDocumentViewer = (props: TextDocumentViewerProps) => {
  const { citation, document, hasError } = props;
  const contentNodeRef = React.useRef<HTMLDivElement>(null);
  const [markdown, setMarkdown] = useState<React.ReactNode | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(
    function handleDocumentChange() {
      if (!document) return;

      setMarkdown(null);
      setIsProcessing(true);

      // using setTimeout to make the processing state visible
      setTimeout(() => {
        setMarkdown(
          <Markdown ref={contentNodeRef}>
            {highlightPassages(document as string, citation.chunk)}
          </Markdown>,
        );
        setIsProcessing(false);
      }, 0);
    },
    [document, citation],
  );

  const highlightPassages = (content: string, passage: string): string => {
    let highlightedContent = content;

    // We can't wrap the entire passage in a single <mark> tag.
    // Mark is inline and can't contain block elements like <p> or <div>.
    // Since the passage can span multiple paragraphs, we need to wrap each paragraph in a <mark> tag.
    highlightedContent = highlightedContent.replace(passage, (match) => {
      return match
        .split(/\n+/)
        .map((line) => line.trim())
        .map((line) => {
          return `<mark>${line}</mark>`;
        })
        .join("\n");
    });

    return highlightedContent;
  };

  const scrollToCitation = (): void => {
    const node = contentNodeRef.current?.querySelector("mark");

    if (!node) return;

    node.scrollIntoView();
  };

  useEffect(
    function handleHighlightedContentChange() {
      if (!markdown) return;

      setTimeout(() => {
        scrollToCitation();
      }, 0);
    },
    [markdown],
  );

  const renderContent = () => {
    return (
      <div className={styles.documentViewer}>
        {isProcessing && (
          <div className={chatStyles.documentViewerSpinner}>
            <span style={{ opacity: 0 }}>
              <Spinner />
            </span>
            <Text>Processing the document...</Text>
          </div>
        )}
        {hasError && <ChatSidebarError errorMessage="Can't load the file..." />}
        {!hasError && !isProcessing && (
          <div className={styles.content}>{markdown}</div>
        )}
      </div>
    );
  };

  return renderContent();
};
